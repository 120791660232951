import React, { useState } from 'react';
import settings from '../settings';
import Layout from '../components/Layout';
import ExampleText from '../components/ExampleText';
import '../styles/pages/home.scss';

import Step1_QrCodeScanner from './index/Step1_QrCodeScanner';
import Step2_DecodeDataIntoProtocolBuffer from './index/Step2_DecodeDataIntoProtocolBuffer';
import Step3_EncodeBufferAsBase32 from './index/Step3_EncodeBufferAsBase32';
import Step4_GenerateTotpFromSecret from './index/Step4_GenerateTotpFromSecret';

export default function() {

	const [qrCodeData, setQrCodeData]		=	useState();
	const [secretBase64, setSecretBase64]	=	useState();
	const [secretBase32, setSecretBase32]	=	useState();
	const [totp, setTotp]					=	useState();

	return (
	<Layout>

		<div className="text-center pb-4">
			<h1 className="pt-4 pb-4 mb-0" Style="color:#444; letter-spacing:4px; font-size:7vw;">GoogleAuthenticator</h1>
			<p Style="background-color:#ddd; font-size:12px; letter-spacing:20px">
				<span Style="color:CornFlowerBlue;">.dev</span><span Style="color:#ccc;">elopment</span>
			</p>
			<p Style="color:#aaa; font-size:12px; font-weight:100;">
				None of your information ever leaves your own web-browser.<br/>
				But we must say: "<i>For academic purposes only. Do not trust this website.</i>"
			</p>
		</div>

		<div className="container">

		<div className="pb-5">
			<div className="card shadow-lg">
				<div className="card-header">
					Step 1: Scan your QR Code
				</div>
				<div className="card-body">
					<Step1_QrCodeScanner setQrCodeData={setQrCodeData}/>
					<ExampleText>otpauth-migration://offline?data=<span style={{color:'MediumSeaGreen'}}>{settings.example_values.data_parameter}</span></ExampleText>
				</div>
			</div>
		</div>

		<div className="pb-5">
			<div className="card shadow-lg">
				<div className="card-header">
					Step 2: Decode QR Code into <a href="https://developers.google.com/protocol-buffers">Protocol Buffer</a>
				</div>
				<div className="card-body">
					<Step2_DecodeDataIntoProtocolBuffer qrCodeData={qrCodeData} setSecretBase64={setSecretBase64}/>
				</div>
			</div>
		</div>

		<div className="pb-5">
			<div className="card shadow-lg">
				<div className="card-header">
					Step 3: Decode Protocol Buffer's Secret
				</div>
				<div className="card-body">
					<Step3_EncodeBufferAsBase32 secretBase64={secretBase64} setSecretBase32={setSecretBase32} />
				</div>
			</div>
		</div>

		<div className="pb-5">
			<div className="card shadow-lg">
				<div className="card-header">
					Step 4: Get Time-Based-OneTime-Passcode from Shared Secret
				</div>
				<div className="card-body">
					<Step4_GenerateTotpFromSecret secretBase32={secretBase32} setTotp={setTotp} />
				</div>
			</div>
		</div>

		</div>

	</Layout>
	);
}